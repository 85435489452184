import React from "react";
import { Element } from "react-scroll";

import { Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/unhavok/Hero";
import Works from "../sections/unhavok/Works";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Box bg="primary">
          <Hero />
          <Element name="works">
            <Works />
          </Element>
          {/* <CTA /> */}
        </Box>
      </PageWrapper>
    </>
  );
};
export default IndexPage;
