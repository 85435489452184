import hipherdImg from '../assets/images/portfolio/hipherd.png'
import fleeCovidImg from '../assets/images/portfolio/fleecovid.png'

export const unhavokProducts = [
  {
    brand: `HipHerd`,
    slug: `hipherd-app`,
    title: `An easy & free trip planning tool for modern travelers`,
    categories: [`app`],
    thumbnail: hipherdImg,
    url: 'https://hipherd.com?ref=unhavok'
  },
  {
    brand: `FleeCOVID`,
    slug: `fleecovid-app`,
    title: `Travel responsibly during COVID-19.`,
    categories: [`web`],
    thumbnail: fleeCovidImg,
    url: 'https://fleecovid.com?ref=unhavok'
  },
];
